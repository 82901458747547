<template>
  <div>
    <div class="operate">
      <div class="operate-item link">
        <span>输入长链接:</span
        ><input v-model="url" @blur="clear_img" placeholder="输入链接" />
      </div>
      <div class="operate-item file">
        <input type="file" @change="onFileChange" />
      </div>
      <div class="operate-item front-color">
        <span class="demonstration">选择前景色</span>
        <el-color-picker v-model="color1" color-format="rgb" show-alpha>
        </el-color-picker>
      </div>
      <div class="operate-item front-color">
        <span class="demonstration">选择背景色</span>
        <el-color-picker v-model="color2" color-format="rgb" show-alpha>
        </el-color-picker>
      </div>

      <!-- <div class="operate-item choice-color">
        <button @click="showFrontColorBtn">选择前景色</button>

        <section v-if="show_front_color">
          <div
            class="color_con"
            :style="{ background: color }"
            @click="handleShowColor"
          ></div>
          <sketch-picker v-model="color" @input="updateValue"></sketch-picker>
        </section>
      </div>
      <div class="operate-item choice-color">
        <button @click="showBgColorBtn">选择背景色</button>
        <section v-if="show_bg_color">
          <div
            class="color_con"
            :style="{ background: color }"
            @click="handleShowColor"
          ></div>
          <sketch-picker v-model="color" @input="updateValue"></sketch-picker>
        </section>
      </div> -->
      <div class="button">
        <div class="generate-qrcode-btn">
          <a
            @click="generateQRCode"
            @mousedown="mouse_down"
            @mouseup="mouse_up"
            :class="click_generate_btn ? 'click-bg-color' : 'normal-bg_color'"
            ><span>生成二维码</span></a
          >
        </div>
      </div>
    </div>
    <!-- <qrcode :value="qrCodeValue" :options="{ size: 200 }"></qrcode> -->

    <div class="QRCode-img">
      <div
        v-if="qrCodeValue != null && qrCodeValue.length > 0"
        class="generated-img"
      >
        <h3>下面是生成的二维码，用手机扫码即可访问</h3>
        <i></i>
        <img :src="qrCodeValue" ref="qrCodeImg" />
      </div>

      <div v-if="qrCodeValue == null || qrCodeValue.trim() == ''" class="empty">
        <el-empty description="未生成二维码"></el-empty>
      </div>
    </div>

    <div class="download" v-if="qrCodeValue != null && qrCodeValue.length > 0">
      <a
        class="download-img-btn"
        @click="down_qrcode"
        @mousedown="qrcode_mouse_down"
        @mouseup="qrcode_mouse_up"
        :class="click_down_qrcode ? 'click-bg-color' : 'normal-bg_color'"
      >
        <span>点击下载二维码</span>
      </a>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@xkeshi/vue-qrcode";
import { Sketch } from "vue-color";
import { Loading } from "element-ui";
export default {
  name: "LinkToQRCode",
  components: {
    VueQrcode,
    "sketch-picker": Sketch,
  },
  data() {
    return {
      url: "",
      qrCodeValue: null,
      color: "#000",
      file: null,
      base64Img: "",
      qrCodeImg: "",
      show_front_color: false,
      show_bg_color: false,
      colorShow: false,
      color1: "",
      color2: "",
      click_generate_btn: false,
      loadingInstance: null,
      click_down_qrcode: false,
    };
  },
  methods: {
    down_qrcode() {
      const link = document.createElement("a"); // 创建下载链接

      // 从Base64数据创建Blob URL
      const blob = this.dataURItoBlob(this.qrCodeValue);
      const url = window.URL.createObjectURL(blob);

      // 设置下载链接的属性
      link.href = url;
      link.download = "定制化二维码.png"; // 指定下载文件的名称

      // 触发点击事件
      document.body.appendChild(link);
      link.click();

      // 下载完成后清理资源
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]); // 解码Base64
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]; // 获取MIME类型
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },
    handleShowColor() {
      if (this.colorShow) {
        this.colorShow = false;
      } else {
        this.colorShow = true;
      }
    },
    showBgColorBtn() {
      this.show_bg_color = !this.show_bg_color;
    },
    showFrontColorBtn() {
      // console.log("卡景色");
      this.show_front_color = !this.show_front_color;
    },

    updateValue(val) {
      // console.log("颜色选择", val.rgba); // 输出参数如下图1-1
      this.color = val.rgba;

      // console.log("rgb值", val.rgba.r, val.rgba.g, val.rgba.b, val.rgba.a);
    },

    rgbaToHex(rgba_json) {
      const r = rgba_json.r;
      const g = rgba_json.g;
      const b = rgba_json.b;
      const a = rgba_json.a;
      // 将RGB值四舍五入到最近的整数，并转换为16进制
      const toHex = (value) => Math.round(value).toString(16).padStart(2, "0");
      const hexRGB = toHex(r) + toHex(g) + toHex(b);

      // 将透明度A转换为16进制，保留2位小数
      const hexAlpha = Math.round(a * 255)
        .toString(16)
        .padStart(2, "0");

      // 返回完整的16进制颜色字符串
      return `#${hexRGB}${hexAlpha}`;
    },

    mouse_down() {
      // console.log("点击按下鼠标");
      this.click_generate_btn = true;
    },

    mouse_up() {
      // console.log("点击松开鼠标");
      this.click_generate_btn = false;
    },
    // async generateQRCode() {
    //   const response = await this.$http({
    //     method: "get",
    //     url: `http://www.soutudashi.com/generate-qrcode?url=${this.url}`, // 拼接URL和参数
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*", // 设置跨域参数
    //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    //     },
    //     responseType: "json",
    //   });

    //   console.log(
    //     "code: ",
    //     response,
    //     response.data,
    //     response.data != undefined,
    //     response.data.length
    //   );
    //   if (response.data != undefined && response.data.length > 0) {
    //     this.qrCodeValue = response.data;
    //   } else {
    //     this.$message({
    //       message: "提交成功，请等待管理员审核！",
    //       type: "success",
    //     });
    //   }
    // },

    clear_img(event) {
      // console.log("触发blue函数拉");

      if (this.url == null || this.url == undefined || this.url.length == 0) {
        this.qrCodeValue = null;
      }
    },

    // 将rgba颜色字符串转化为rgba四个值
    color_to_rgba(rgba_str) {
      const match = rgba_str.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.?\d*))?\)/
      );
      return match
        ? {
            r: parseInt(match[1], 10),
            g: parseInt(match[2], 10),
            b: parseInt(match[3], 10),
            a: parseFloat(match[4] || "1"),
          }
        : null;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (!file) return;

      this.file = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.base64Img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    generateQRCode() {
      // console.log(
      //   "16进制值： ",
      //   this.color1,
      //   "color1",
      //   this.rgbaToHex(this.color_to_rgba(this.color1)),
      //   "color2",
      //   this.color2,
      //   this.rgbaToHex(this.color_to_rgba(this.color2))
      //   // this.rgbaToHex(val.rgba.r, val.rgba.g, val.rgba.b, val.rgba.a)
      // );

// console.log("传入参数： ",this.url,this.base64Img,this.color1,this.color2)
if(!(this.url && this.base64Img && this.color1 && this.color2)){
   this.$message({
    type:"error",message:"填写的信息不全，请填写完整后在提交(链接地址/上传图片/二维码背景色/二维码前景色)",duration:1000,showClose: true,
   })
   return;
}


      let frontColor = this.rgbaToHex(this.color_to_rgba(this.color1));
      let bgColor = this.rgbaToHex(this.color_to_rgba(this.color2));

      if (!this.url || !this.base64Img) {
        alert("Please enter a URL and upload an image.");
        return;
      }



      const formData = new FormData();
      formData.append("url", this.url);
      formData.append("image", this.base64Img);
      formData.append("frontColor", frontColor);
      formData.append("bgColor", bgColor);


      // 开启loading加载效果
      this.loadingInstance = Loading.service({
        fullscreen: true,
        lock: true,
        text: "正在努力生成二维码，请稍候...",
        // spinner: "el-icon-loading",
        target: this.$refs.qrCodeImg, // 指定元素ID
      });
      // console.log("开启loading加载效果");
      this.$http
        .post("http://www.soutudashi.com/generate-qrcode", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log("前端收到后端返回的数据： ", response.data);

          this.qrCodeValue = response.data.qrCode;
          // this.qrCodeImg = URL.createObjectURL(
          //   new Blob([response.data.], { type: "image/png" })
          // );

          // console.log("this.qrCodeImg", this.qrCodeValue, response.data.qrCode);
          // 清除loading加载效果
          this.loadingInstance.close();
        })
        .catch((error) => {
          console.error("Error generating QR Code:", error);
        });
    },

    qrcode_mouse_down() {
      // console.log("点击按下鼠标");
      this.click_down_qrcode = true;
    },

    qrcode_mouse_up() {
      // console.log("点击松开鼠标");
      this.click_down_qrcode = false;
    },
  },
};
</script>

<style scoped>
.operate {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 320px;
  margin: 0px auto;
}

.operate > .operate-item {
  width: 100%;
  display: flex;
  gap: 30px;
  line-height: 40px;
  height: 40px;
  padding: 10px 5px;
}
.QRCode-img {
  /* margin: 20px auto; */
  margin-top: 20px;
}
img {
  height: 300px;
  width: 300px;
}

.generate-qrcode-btn {
  width: 200px;
  height: 70px;
  /* background-color: green; */
  margin: 0px auto;
}

.normal-bg_color {
  background-color: lightblue;
}

.click-bg-color {
  background-color: green;
}

.generate-qrcode-btn > a {
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
  line-height: 70px;
}

.generate-qrcode-btn > a:hover {
  cursor: pointer;
}
.empty {
  width: 320px;
  height: 320px;
  border: 2px dashed black;
  margin: 20px auto;
}

.download-img-btn {
  width: 200px;
  height: 50px;
  display: block;
  margin: 0px auto;
  /* background-color: green; */
}

.download-img-btn:hover {
  cursor: pointer;
}

.download-img-btn > span {
  line-height: 50px;
}
</style>
